@import '../../../../../Style/variables';

.search-user-container {
   .role-select {
      margin-top: 14px;
   }
   .controls {
      text-align: right;
      button {
         margin: 5px;
      }
   }

   .user-search-result {
      .status-cell {
         text-align: center;
         .status-icon {
            padding: 5px;
            span {
               margin-left: 5px;
            }
         }
      }

      .user-edit-dialog {
         width: 100%;
         .md-dialog {
            width: 65%;
            .md-dialog-content--padded {
               margin: 0;
               padding: 0;
            }
            .add-user-panel {
               margin: 0;
            }
         }
         .md-expansion-panel-list {
            margin: 0;
            padding: 0;
            width: 100%;
         }
      }
   }
}
