@import 'variables';

//================== Fonts ==================
@font-face {
   font-family: 'Material-Icons-Round';
   src: url('/fonts/Material-Icons-Round.ttf') format('truetype');
}

@font-face {
   font-family: 'HelveticaNeueLTPro-Roman';
   src: url('/fonts/38CC3B_0_0.eot');
   src: url('/fonts/38CC3B_0_0.eot?#iefix') format('embedded-opentype'),
      url('/fonts/38CC3B_0_0.woff2') format('woff2'),
      url('/fonts/38CC3B_0_0.woff') format('woff'),
      url('/fonts/38CC3B_0_0.ttf') format('truetype');
}

@font-face {
   font-family: 'HelveticaNeueLTPro-Md';
   src: url('/fonts/38CC3B_1_0.eot');
   src: url('/fonts/38CC3B_1_0.eot?#iefix') format('embedded-opentype'),
      url('/fonts/38CC3B_1_0.woff2') format('woff2'),
      url('/fonts/38CC3B_1_0.woff') format('woff'),
      url('/fonts/38CC3B_1_0.ttf') format('truetype');
}

@font-face {
   font-family: 'HelveticaNeueLTPro-Bd';
   src: url('/fonts/38CC3B_2_0.eot');
   src: url('/fonts/38CC3B_2_0.eot?#iefix') format('embedded-opentype'),
      url('/fonts/38CC3B_2_0.woff2') format('woff2'),
      url('/fonts/38CC3B_2_0.woff') format('woff'),
      url('/fonts/38CC3B_2_0.ttf') format('truetype');
}

//================== CSS ==================

i.round {
   font-family: 'Material-Icons-Round';
}

body {
   background-color: $pgdb-background;
   display: flex !important;
   min-height: 100vh !important;
   flex-direction: column !important;
   font-family: 'HelveticaNeueLTPro-Roman' !important;
}

* {
   font-family: 'HelveticaNeueLTPro-Roman';
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: 'HelveticaNeueLTPro-Roman';
}

header {
   z-index: 100;
}

#main-content {
   margin-top: 80px;
   position: relative;
}

.buttons-group {
   text-align: center;
   button:not(:last-child) {
      margin-right: 5px;
   }
}

.red-btn {
   background: $pgdb-red;
}

.green-btn {
   background: $pgdb-green;
   color: $pgdb-white;
}

.btn-small {
   padding: 8px 16px;
   font-size: 13px;
}

.center {
   margin: auto;
   width: 50%;
   padding: 10px;
   text-align: center;
}

.pgdb-logo {
   height: 39px;
   width: 49px;
   padding-left: 5px;
   padding-right: 5px;
}

.pgdb-env-indicator {
   width: 200px;
   padding-left: 5px;
   padding-right: 5px;
}

.pgdb-env-indicator-mini {
   width: 30px;
}

.center-logo {
   margin: auto;
   width: 75%;
   text-align: center;
}

.grey-background {
   background-color: $pgdb-card-grey;
   border-radius: 6px;
}

.text-orange {
   color: $pgdb-orange;
}

.text-red {
   color: $pgdb-red;
}

.text-green {
   color: $pgdb-green;
}

.bold-font {
   font-family: HelveticaNeueLTPro-Bd;
}

.small-icon {
   font-size: 17px;
}

.letter-btn {
   font-size: 17px;
   padding-top: 1px;
   position: absolute;
   right: 95px;
   top: 17px;
   .md-icon {
      font-size: 17px;
      font-size: 17px;
   }
   p {
      font-size: 9px;
      margin-top: -8px;
      margin-bottom: 0px;
   }
}

.plus-btn {
   font-size: 17px;
   padding-top: 1px;
   position: absolute;
   right: 45px;
   top: 17px;
   .md-icon {
      font-size: 17px;
      font-size: 17px;
   }
   p {
      font-size: 9px;
      margin-top: -8px;
      margin-bottom: 0px;
   }
}

td {
   span {
      font-family: HelveticaNeueLTPro-Bd;
   }
}

.nowrap {
   white-space: nowrap;
}

.center-icon {
   justify-content: center;
   align-items: center;
   display: flex;
}

.align-embed-column {
   margin-top: -25px;
   margin-left: -16px;
}

.btn-padding {
   margin-right: 20px;
}

.disable-link-styles {
   color: inherit;
   text-decoration: inherit;
}

.input-error {
   input {
      border: 1px solid $pgdb-red;
   }
}
