@import '../../../../../Style/variables';

.advanced-filter-button {
   margin-left: auto;
   color: $pgdb-activeblue;
   font-weight: 600;
   text-decoration: none;
}

.download-receipt-button {
   height: 20px;
}
