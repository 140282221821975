@import '../../../Style/variables';

.user-card {
   background-color: $pgdb-card-grey;
   border-radius: 6px;
   padding: 0px;
   padding-left: 10px;
   padding-right: 10px;

   .md-icon {
      font-size: 18px;
      vertical-align: middle;
      color: $pgdb-blue;
      margin-right: 25px;
      border: 1px solid $pgdb-blue;
      width: 32px;
      height: 32px;
      padding: 6px;
      border-radius: 50%;
   }

   .contact-wrapper {
      padding-top: 20px;
      padding-left: 60px;
   }

   .contact-details {
      line-height: 18px;
      font-size: 16px;
      margin-bottom: 3px;
   }

   .user-card {
      display: flex;

      .profile-photo {
         height: 80px;
         min-width: 80px;
         width: 80px;
         box-shadow: 0 0 0 4px $pgdb-card-grey, 0 0 0 5px $pgdb-blue;
      }

      .md-icon-text {
         font-size: 24px;
         flex-shrink: 1;
         text-overflow: ellipsis;
         overflow: hidden;
         white-space: nowrap;
      }

      .flag-selected {
         color: $pgdb-flag-red;
         float: right;
      }

      .birthday {
         font-size: 14px;
         margin-top: 11px;
      }

      .right-container {
         justify-content: flex-end;
         text-align: right;

         .licence-id {
            color: $pgdb-activeblue;
            display: flex;
            margin-top: 16px;
         }

         .licence-id-wrapper {
            display: flex;
            flex-direction: row-reverse;
         }

         .status-text {
            font-size: 14px;
            margin-top: -12px;
         }
      }
   }
}
