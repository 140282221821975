.invoice-payments-loader {
   .loading-wrapper-main {
      position: inherit;

      .loading-wrapper-content {
         height: 63px;
         width: 223px;
         margin: 0 auto;
      }
   }
}

.invoice-payments-padding {
   margin: 40px;
   height: 150px;
}

.no-margin-top {
   margin-top: 0px !important;
}

.back-button {
   .md-btn--flat {
      border-radius: 4px;
      padding: 0px 10px 0px 10px;
   }

   .md-icon-text:last-child {
      padding-left: 5px;
   }
}
