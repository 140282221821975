@import '../../../../../Style/variables';

.personal-details-panel {
   .contact-list-item {
      border-bottom: 1px solid $pgdb-grey;
      margin: 10px;
      padding: 0 16px;
      display: flex;
      height: 48px;
      align-items: center;
   }

   .listitem-last-outline {
      margin: 10px;
   }

   .rightpanel {
      background-color: $pgdb-verylightgrey;
      border-radius: 6px;
   }

   .panel-header {
      display: flex;
      background-color: $pgdb-white;
      margin: 10px;
      padding: 10px;
      color: $pgdb-green;
      border-radius: 6px;
      height: 76px;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
   }

   .panel-subheader {
      color: $pgdb-orange;
      font-weight: bold;
      line-height: 25px;
      padding: 10px 10px 10px 30px;
   }

   .icon-size {
      height: 30px;
      width: 30px;
   }

   .header-icon {
      margin-right: 28px;
   }

   .star-wrapper {
      display: flex;
      align-items: center;
   }

   .green-star {
      color: $pgdb-green;
      margin-left: 10px;
   }

   .list-icon {
      color: $pgdb-blue;
      margin-right: 28px;
   }

   .md-tile-addon--avatar {
      display: flex;
      align-items: center;
   }
}
