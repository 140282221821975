@import '../../../Style/variables';

.icon-outline {
   display: flex;
   border-radius: 50%;
   box-sizing: border-box;
   justify-content: center;
   align-items: center;
   border: 2px solid $pgdb-blue-2;
   font-size: 16.5px;
   height: 30px;
   width: 30px;
}

.icon-outline-header {
   display: flex;
   border-radius: 50%;
   box-sizing: border-box;
   justify-content: center;
   align-items: center;
   border: 1px solid $pgdb-green;
   color: $pgdb-green;
   font-size: 30.5px;
   height: 46px;
   width: 46px;
}
