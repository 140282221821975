@import '../../../../../../Style/variables';

.FitAndProperPanel {
    width: 100%;

    .edit-btn {
     font-size: 17px;
    }

    .question-column {
      white-space: break-spaces;
      text-align: left;
    }

    .answer-column {
      text-align: center;
    }

    .button-column {
      text-align: center;
    }
 
    .fa-check {
       color: $pgdb-green;
    }
    .fa-times {
       color: $pgdb-flag-red;
    }
 
    .rt-tbody .rt-td {
       border-right: none;
    }
    
    .rt-tbody .rt-tr-group {
       border-bottom: none;
    }

    .text-style-No {
      color: green;
    }
    .text-style-Yes {
      color: darkred;
   }
 }
 
 .fitandproper-edit-dialog {
   z-index: $pgdb-z-order-2;
   .md-dialog {
      width: 75%;

      .md-dialog-content {
         padding: 0;
         margin: 0;
      }
   }
 }

 .fitandproper-history-dialog {
   z-index: $pgdb-z-order-2;
   .md-dialog {
      width: 85%;
      max-height: 80%;

      .md-dialog-content {
         padding: 0;
         margin: 0;
      }
   }
 }