@import '../../../../Style/variables';

.licence-year-selection,
.licence-status-selection {
   margin-top: 0;
   .md-list-tile {
      height: 25px;
   }
   .md-list {
      max-height: 150px;
   }
}

.assessments-table .ReactTable {
   .expandable-cell {
      overflow: visible;
      padding: 0;
      .editable-result-text,
      .editable-year-text {
         display: inline-block;
         margin: 10px;
      }
      .result-status-selection,
      .licence-year-selection {
         margin: 5px;
      }
      .md-text-field {
         padding: 0 10px;
         height: 30px;
      }
   }
   .md-btn--flat {
      padding: 0;
   }
   .fa-times {
      margin-left: 10px;
   }
}
