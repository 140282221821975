@import '../../../Style/variables';

.assessment-results-card {
   background-color: $pgdb-card-grey;
   border-radius: 6px;
   padding: 0px;
   padding-left: 10px;
   padding-right: 10px;
}

.button-close {
   position: absolute;
   right: 10px;
   top: 10px;
}

.assessment-result {
   display: inline-block;
   margin-right: 40px;
   margin-left: 30px;
   padding: 10px 0;
   p,
   button {
      font-size: 16px;
      display: inline;
      margin-left: 35px;
   }
   .exam-eligibility-btn {
      margin-left: 0px;
      padding: 0;
      height: auto;
      .fa-angle-down {
         position: relative;
         top: 2px;
         left: -5px;
         font-size: 20px;
      }
   }
   i {
      position: absolute;
      margin-top: -2px;
   }
   .red {
      color: $pgdb-red;
   }
   .green {
      color: $pgdb-green;
   }
   .md-menu-container {
      margin-top: 0;
   }
}
