@import '../../../Style/variables';

.payment-container {
   .md-menu--select-field {
      width: 100%;
   }

   .md-data-table {
      border-bottom: 1px solid $pgdb-black-2;
   }

   .select-menu {
      height: 200px;
   }

   .payment-status-info {
      font-size: 12px;
      display: inline-block;
      width: 100%;
      text-align: right;
      padding: 5px 5px 0 0;
   }

   .create-fee-request-container {
      padding-bottom: 0;
   }

   .link-create-fee-request {
      margin-left: auto;
      margin-right: 8px;
      font-size: 14px;
   }

   .link-create-fee-request:hover,
   .link-create-fee-request:active,
   .link-create-fee-request:visited,
   .link-create-fee-request {
      text-decoration: none;
      color: inherit;
   }

   .total-amount {
      input.md-text-field {
         text-align: right;
      }
   }

   .control-panel {
      text-align: right;
      button {
         margin-left: 5px;
      }
      padding-bottom: 20px;

      .btn-add-to-multi-pay {
         .small-icon {
            margin-right: 15px;
         }
      }

      .right {
         float: left;
      }
   }

   .fee-request-table {
      .btn-remove-multi-pay {
         padding: 2px;
         font-size: x-small;
      }
      .btn-remove-multi-pay span {
         opacity: 0;
         display: inline-block;
         text-decoration: none;
         max-width: 0;
         transition: max-width 0.5s ease-out 0.001s, opacity 0.1s ease-out 0.1s,
            color;
         color: $pgdb-red;
         .cart {
            display: inline-block;
         }
      }
      .btn-remove-multi-pay:hover span {
         opacity: 1;
         max-width: 100px;
         transition: max-width 0.5s ease-out 0.001s, opacity 0.1s ease-out 0.1s,
            color 0.2s;
         .cart {
            display: none;
         }
      }
      .added-in-multipay {
         td {
            background-color: $pgdb-background-selected-rows;
         }
      }
      .currency-cell {
         text-align: right;
         position: relative;
      }

      .currency-symbol {
         float: left;
      }

      .total-label-cell {
         text-align: right;
      }

      .fee-request-line-checkbox-cell {
         text-align: right;
      }

      tr.odd-row {
         background: rgba(0, 0, 255, 0.03);
      }

      tr.odd-row.md-table-row--hover {
         background: #eee;
      }

      .fee-request-row {
         border-bottom: none;
         border-top: 1px solid rgba(0, 0, 0, 0.12);
         font-weight: bold;
      }

      .fee-request-line-row {
         border-top: 1px dotted rgba(0, 0, 0, 0.12);
         border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
      }

      .action-button {
         float: left;
         width: 42px;
         height: 40px;
         border-radius: 0;
         padding: 0;
         margin: 0;
      }

      .fee-request-line-edit {
         padding-left: 2px;
      }

      .fee-request-line-view {
         padding-right: 2px;
      }

      .md-table-footer {
         font-weight: bold;
      }

      .link-button {
         width: 42px;
         height: 40px;
      }

      .grey-out-fee-request-line {
         color: $pgdb-grey;
      }

      .unpiad-label {
         color: $pgdb-red;
      }
   }

   .fee-request-line-dialog {
      .md-dialog-content {
         width: 100%;
         height: 100%;
      }
      .md-dialog {
         width: 60%;
         height: auto;
      }
   }
}
