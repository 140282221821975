@import '../../../Style/variables';

.process-review {
   float: right;
}

.review {
   .slick-prev:before,
   .slick-next:before {
      color: rgba(51, 51, 51, 0.35);
      font-size: 40px;
   }
}

.review-card {
   margin-bottom: 10px;
   margin-top: 15px;

   .small-font {
      font-size: 10px;
      padding-bottom: 0px;
      text-align: center;
      width: 100%;
   }

   .right-border {
      border-right: 2px solid $pgdb-darkgrey;
   }

   .search-button {
      margin-top: 12px;
   }

   .md-avatar-img {
      margin: 0 auto;
   }

   .md-icon {
      color: $pgdb-blue;
      margin-right: 28px;
   }

   .profile-image-main {
      max-height: 225px;
      max-height: 225px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 20px;
   }

   .profile-image-history {
      height: 100px;
      max-height: 100px;
      display: block;
      margin-left: auto;
      margin-right: auto;
   }

   .grey-background {
      background-color: $pgdb-card-grey;
      border-radius: 6px;
   }

   .fa-stack {
      cursor: pointer;
   }

   .fa-circle {
      text-shadow: 1px 1px 16px rgba(80, 80, 80, 0.39);
   }

   .fa-heart {
      color: #18e0a6;
   }
   .fa-star {
      color: #259ad9;
   }
   .fa-times {
      color: #fc496d;
   }
   .fa-undo-alt {
      color: #febb09;
   }
   .fa-bolt {
      color: #a04ee2;
   }
}
