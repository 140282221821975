@import '../../../../../Style/variables';

.registration-fit-and-proper-inline {
    border: solid 1px $border-light;
    margin: 10px;
    margin-left: 20px;
    padding: 15px;

    .title {
        text-align: center;
        color: $pgdb-blue-2;
        font-size: medium;
    }

    .section {
        padding: 10px;
        border-bottom: solid 1px $border-light;

        .question-section {
            padding: 5px;
        }

        .option-section {
            padding: 15px;
            text-align: left;
        }

        input[type="radio"] {
            cursor: pointer;
            margin: 5px;

            font: inherit;
            color: $pgdb-activeblue;
            width: 1.4em;
            height: 1.4em;
            border: 0.15em solid $pgdb-activeblue;
            border-radius: 50%;  
          }       
    }

    .section-last {
      margin-bottom: 14px;
      padding: 10px;
      border-bottom: solid 1px $border-light;

      .question-section {
          padding: 5px;
      }

      .option-section {
          padding: 15px;
          text-align: left;
      }

      input[type="radio"] {
          cursor: pointer;
          margin: 5px;

          font: inherit;
          color: $pgdb-activeblue;
          width: 1.4em;
          height: 1.4em;
          border: 0.15em solid $pgdb-activeblue;
          border-radius: 50%;  
        }       
    }
    .section:last-child {
        border: 0;
    }
}