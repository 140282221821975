.sql-reporting {
   .ReactTable .rt-th,
   .ReactTable .rt-td {
      white-space: pre-line;
      overflow: visible;
   }
   code {
      font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono,
         DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace,
         sans-serif;
      background-color: #eff0f1;
   }
   pre {
      margin-bottom: 1em;
      padding: 12px 8px;
      padding-bottom: 20px;
      width: auto;
      max-height: 600px;
      overflow: auto;
      font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono,
         DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace,
         sans-serif;
      font-size: 13px;
      background-color: #eff0f1;
      border-radius: 3px;
   }

   .container-outer {
      display: flex;
      width: 100%;
      flex-direction: row;
   }

   .btn-group {
      display: flex;
      flex: 1;
      justify-content: flex-end;
   }

   .edit-btn {
      padding-left: 17px;
   }

   .add-btn {
      margin-top: 23px;
   }

   .delete-btn {
      margin-right: 100px;
   }
}
