@import '../../../Style/variables';

.review {
   .md-card {
      background: linear-gradient(white, white) padding-box,
         $pgdb-light-blue-grad border-box;
      border-left: 11px solid transparent;
      border-radius: 6px;
   }

   .md-icon {
      color: $pgdb-blue;
      margin-right: 28px;
   }
}
