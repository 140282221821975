@import '../../../Style/variables';

.application-review {
   .check-box-application {
      .md-btn--icon {
         padding: 2px;
      }
   }
   .app-review-controls {
      text-align: right;
      button {
         margin-left: 10px;
      }
   }
}

.application-status-confirmation {
   .approve {
      color: $pgdb-blue-2;
   }

   .decline {
      color: $pgdb-flag-red;
   }
}
