@import '../../../Style/variables';

.payment-checkout-container {
   .total-amount {
      input.md-text-field {
         text-align: right;
      }
   }

   .md-text--error {
      text-align: left;
   }

   .error-text {
      text-align: left;
   }

   .windcave-logo {
      width: 140px;
   }
}
