.supervision-status {
   margin-top: 30px;
}

.criteria-header {
   font-size: 23px;
   font-weight: bold;
}

.payment-status-detail {
   font-size: 12px;
   display: inline-block;
   width: 100%;
   text-align: right;
   padding: 0 15px 0 0;
}

@media (max-width: 1458px) {
   .criteria-view-panel-button-container {
      display: flex;
   }
   .criteria-view-panel-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px !important;
      padding-bottom: 30px !important;
   }
}

#overwrite-status-update-dialog {
   .md-dialog-content .md-dialog-content--padded {
      max-height: 300px;
   }
}

#overwrite-status-height {
   width: 100%;
   height: 290px;
}

#overwrite-status-dialog {
   height: 320px;
}
