.financial-extract {
   .table-headings {
      font-family: 'HelveticaNeueLTPro-Bd';
      color: black;
   }

   .total-cell-label {
      font-weight: bold;
   }

   hr.grand-total-bottom {
      border: 1px solid black;
   }

   hr.grand-total-top {
      border: 0.5px solid black;
   }

   .total-row-label,
   .total-cell-label {
      text-align: right;
   }

   .no-row{
      text-align: center;
   }
}
