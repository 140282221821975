@import '../../../Style/variables';

#exam-dialog {
   min-height: 300px;
   .md-card-title {
      padding-bottom: 5px;
   }
   .md-card-text {
      padding-top: 5px;
      padding-bottom: 10px;
   }
   .plumber-card {
      background: linear-gradient(white, white) padding-box,
         $pgdb-purple-grad border-box;
      border-left: 11px solid transparent;
   }
   #exam-dialog-title {
      padding-bottom: 0;
   }

   .md-menu-container,
   .md-text-field-container {
      margin-top: 10px;
   }

   .gasfitter-card {
      background: linear-gradient(white, white) padding-box,
         $pgdb-orange-grad border-box;
      border-left: 11px solid transparent;
   }

   .drainlayer-card {
      background: linear-gradient(white, white) padding-box,
         $pgdb-green-grad border-box;
      border-left: 11px solid transparent;
   }

   .eligibility-buttons {
      width: 100%;
      .save-button {
         float: right;
      }
   }
}

.not-eligible {
   width: 100%;
   .not-eligible-card {
      background: $pgdb-darkgrey;
      .not-eligible-icon {
         font-size: 90px;
         color: $pgdb-white;
      }
      .md-card-text {
         color: $pgdb-activeblue;
      }
      h3 {
         color: $pgdb-white;
      }
   }
}

.other-text-field.disabled {
   display: none;
}

.btn-disabled,
.btn-disabled:hover {
   background: $pgdb-grey;
}

.field-error {
   #other-text-field {
      border-color: $pgdb-red;
   }
}
