@import '../../../Style/variables';

.auth-loading {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-image: url('/images/loading.svg');
   background-repeat: no-repeat;
   background-position-x: 50%;
   background-position-y: 50%;
}

.auth-error {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;

   div {
      margin: 100px auto;
      width: 600px;
      p {
         font-weight: bold;
         line-height: 30px;
         font-size: 20px;
         color: $pgdb-red;
      }
   }
}
