@import '../../../Style/variables';

.dm-section-header {
   font-weight: bold;
   margin: 5px 0 5px 0;
   font-size: 16px;
}

.dm-section-value {
   padding: 10px 0 10px;
}

.add-matter-button:disabled,
.edit-matter-button:disabled {
   color: $pgdb-white;
   background-color: $pgdb-grey;
}
