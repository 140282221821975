@import '../../../../../../Style/variables.scss';

.notes-container {
   position: relative;
   max-width: 1200px;
   margin: 0 auto;

   &::after {
      content: '';
      position: absolute;
      width: 1px;
      background-color: $pgdb-white;
      top: 3em;
      bottom: 0;
      left: 2em;
      margin-left: 0;
      padding-top: 10em;
      border: 1px solid $pgdb-lightgrey;
   }

   .note-item-container {
      padding: 10px 40px 0px;
      position: relative;
      background: inherit;
      width: 100%;

      &.first-item {
         &::after {
            width: 55px;
            height: 55px;
            left: 0;
         }
      }

      &::after {
         content: '';
         position: absolute;
         width: 25px;
         height: 25px;
         left: 1.15em;
         background: $pgdb-purple-grad;
         top: 1.5em;
         border-radius: 50%;
         z-index: 1;
      }

      .note-item-content {
         padding: 0px 30px;        
         background-color: $pgdb-white;
         position: relative;
         border-radius: 6px;
      }
   }
}
