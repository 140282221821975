@import '../../../Style/variables';

@keyframes pop-in-anim {
   0% {
      transform: scale(0);
   }
   33% {
      transform: scale(1.3);
   }
   66% {
      transform: scale(1);
   }
}

.home {
   animation: pop-in-anim 0.8s;
   padding-top: 50px;
   text-align: center;

   h1 {
      padding: 20px;
   }
}

.home-logo {
   height: 80px;
   width: 90px;
   padding-left: 5px;
   padding-right: 5px;
}
