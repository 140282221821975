@import '../../../../Style/variables';

.search-buttons {
   text-align: right;
   .search {
      margin-right: 10px;
   }
}

.cpd-results {
   .rt-tbody {
      overflow: visible;

      .md-menu--select-field {
         margin: 0;
      }

      .overflow-visible {
         overflow: visible;
      }

      .md-list--menu-restricted {
         max-height: 400px;
      }
   }
}

.red {
   color: $pgdb-red;
}
.green {
   color: $pgdb-green;
}

.result-margin-top {
   margin-top: 25px;
}

.remove-result-row {
   font-size: 20px;
   color: $pgdb-lightgrey;
}

.result-line {
   border-bottom: 1px solid $pgdb-darkgrey;
}

.add-results,
.clear-results {
   float: right;
}

.clear-results {
   margin-left: 10px;
}

#add-result-course-code-menu-options {
   max-height: 200px;
}

.csv-input {
   margin-right: 10px;
   label {
      padding-top: 27px;
      padding-bottom: 27px;
   }
}

.edit-course-modal {
   .form-section {
      margin-bottom: 20px;
   }

   .cancel-button {
      margin-left: 8px;
   }

   .form-input {
      margin-top: 0;
      margin-left: 5px;
   }

   .md-menu-container,
   .md-text-field-container {
      margin-top: 0;
   }
}
