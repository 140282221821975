.file-upload-input-container {
   .action-buttons-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .file-upload-input {
         vertical-align: middle;

         > .md-btn {
            padding: 1.75em 1em;
            margin: 0 0.5em;
         }

         .md-icon {
            margin: 0;
         }
      }

      .md-icon {
         color: #000;
      }
   }
}
