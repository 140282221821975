.accordion__button{
   display: flex;
   justify-content: space-between;
   flex-direction: row-reverse;
   background-color: white;
   font-weight: bold;
}

.accordion__button::before{
   transform: rotate(45deg);
}

.accordion__button[aria-expanded="true"]::before, .accordion__button[aria-selected="true"]::before {
   transform: rotate(225deg);
   margin-top: 5px;
 }