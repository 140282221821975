.file-box {
   border: solid 1px #d6d6d6;
   border-radius: 8px;
   padding: 10px;
   margin-top: 20px;
   margin-bottom: 20px;
}

.file-upload-buttons {
   display: flex;
   margin-left: 20px;
}

.file-accept-button {
   margin-left: 5px;
   margin-right: 5px;
   border: #5ec760 2px solid;
   color: #5ec760;
   background-color: white !important;
   text-align: center;
   padding: 20px 20px 35px 20px !important;
}

.file-accepted-button {
   margin-left: 5px;
   margin-right: 5px;
   border: #5ec760 2px solid;
   color: white;
   background-color: #5ec760 !important;
   text-align: center;
   padding: 20px 20px 35px 20px !important;
}

.file-decline-button {
   margin-left: 5px;
   margin-right: 5px;
   border: #be2648 2px solid;
   color: #be2648;
   background-color: white !important;
   text-align: center;
   padding: 20px 20px 35px 20px !important;
}

.request-document-button {
   display: flex;
   align-items: center;
}

.request-document-button-text {
   display: flex;
   align-items: center;
   justify-content: center;
   padding-top: 30px !important;
   padding-bottom: 30px !important;
}

.btn-link-style {
   background: none !important;
   border: none;
   padding: 0 !important;
   /*optional*/
   font-family: arial, sans-serif;
   /*input has OS specific font-family*/
   color: #069;
   text-decoration: underline;
   cursor: pointer;
}

@media (max-width: 1505px) {
   .uploaded-file-name {
      padding-left: 20px;
   }
}
