@import '../../../../Style/variables';

.registration-dialog {
   .control-panel {
      text-align: right;
      button {
         margin-left: 5px;
      }
   }
}
