@import 'variables';

//================== Overrides for React-md ====================
.md-toolbar.md-background--primary {
   background: $pgdb-white;
   color: $pgdb-darkgrey;
}

.md-tabs.md-background--primary,
.md-tab--inactive {
   background: $pgdb-background;
   color: $pgdb-darkgrey;
}

.md-tabs {
   padding-left: 38px;
}

.md-no-scroll.md-no-scroll {
   position: initial;
}

.md-list.md-list--drawer {
   box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.14),
      -3px 8px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
   .md-list.md-list--nested-1 {
      border-radius: 0;
      .md-list-tile {
         padding-left: 40px;
      }
   }
}

.md-list .md-list:not(.md-list--menu) {
   background: $pgdb-lighterblue;
}

header {
   .md-table-column {
      height: 100%;
      display: block;
   }
   .md-table-column:first-child {
      padding-right: 0;
   }
   .md-icon-text {
      font-family: 'HelveticaNeueLTPro-Roman';
      font-size: 16px;
   }
}

.md-drawer {
   box-shadow: none;
   header {
      border: none;
   }
   .md-icon {
      color: rgba(51, 51, 51, 0.35);
      width: 24px;
   }
   .md-tile-content--left-icon {
      padding-left: 25px;
      padding-top: 5px;
   }
   .md-tile-addon--icon.md-text--theme-primary .md-icon {
      color: $pgdb-activeblue;
   }
}

.md-tab-indicator {
   background: $pgdb-activeblue;
}

// Panels
.md-panel-header {
   font-size: 23px;
   font-weight: bold;
}

.md-expansion-panel--expanded:nth-child(2) {
   background: linear-gradient(white, white) padding-box,
      $pgdb-light-blue-grad border-box;
   border-left: 11px solid transparent;
}
.md-expansion-panel--expanded:nth-child(3) {
   background: linear-gradient(white, white) padding-box,
      $pgdb-blue-grad border-box;
   border-left: 11px solid transparent;
}
.md-expansion-panel--expanded:nth-child(4) {
   background: linear-gradient(white, white) padding-box,
      $pgdb-green-grad border-box;
   border-left: 11px solid transparent;
}
.md-expansion-panel--expanded:nth-child(5) {
   background: linear-gradient(white, white) padding-box,
      $pgdb-purple-grad border-box;
   border-left: 11px solid transparent;
}

.md-expansion-panel--expanded {
   &.md-expansion-panel-error {
      background: linear-gradient(white, white) padding-box,
         $pgdb-red-grad border-box;
      border-left: 11px solid transparent;
   }
}

.md-panel-header.md-pointer--hover:hover {
   cursor: inherit;
}

.md-panel-header.md-text {
   color: $pgdb-activeblue;
}

.md-panel-header--expanded {
   padding-left: 3%;
}

.md-panel-column.md-text {
   user-select: none;
}

.md-panel-header--expanded::after {
   background-color: $pgdb-lightgrey;
   bottom: 0;
   content: '';
   display: block;
   height: 2px;
   left: 50%;
   position: absolute;
   transform: translate(-50%, 0);
   width: 95%;
}

.md-expansion-panel {
   border-radius: 6px;
}

.md-text-field {
   border: 1px solid $pgdb-grey;
   background-color: #fff;
   text-align: left;
   border-radius: 6px;
   padding: 15px;
   color: #666;
   margin: 0;
   height: 56px;
   box-sizing: border-box;
   font-family: HelveticaNeueLTPro-Roman, Helvetica Neue Light, Helvetica Neue,
      Helvetica, Arial, Lucida Grande, sans-serif;
   font-size: 100%;
   -webkit-transition: none;
   transition: none;
}

.md-text-field:disabled,
.md-select-field.md-text--disabled .md-select-field--btn {
   background-color: $pgdb-verylightgrey;
}

.md-text-field:focus {
   border: 3px solid $pgdb-activeblue;
}

.md-text--error ~ input:focus {
   border: 3px solid $pgdb-red;
}

.md-menu-container[aria-expanded='true'] {
   .md-text-field {
      border: 3px solid $pgdb-activeblue;
   }
}

.md-divider--text-field {
   display: none;
}

.md-menu-container,
.md-text-field-container {
   margin-top: 23px;
}

.md-menu-container {
   margin-bottom: 0;
}

.md-floating-label--inactive,
.md-floating-label--floating {
   transform: translate3d(10px, -20px, 0);
   color: black;
   font-size: 14px;
   font-family: 'HelveticaNeueLTPro-Bd';
}

.md-text-field-inline-indicator {
   top: 9px;
   right: 10px;

   .md-icon {
      position: relative;
      top: -2px;
      left: -2px;
   }
}

.md-text-field--inline-indicator {
   width: 100%;
}

.md-text-field--floating-margin {
   margin-top: 0;
}

// Custom Button
.md-btn--flat {
   padding-top: 20px;
   padding-bottom: 35px;
   padding-left: 30px;
   padding-right: 30px;
   border-radius: 28px;
   text-align: center;
   font-family: 'HelveticaNeueLTPro-Md';
   line-height: 16px;
   font-size: 16px;
   font-weight: 500;
   text-transform: none;
}

// Misc overrides

.md-table-column--header,
.rt-resizable-header-content {
   font-family: 'HelveticaNeueLTPro-Bd';
   color: black;
}
.md-table-header {
   .md-table-row {
      border-top: 1px solid $pgdb-black-2;
      border-bottom: 1px solid $pgdb-black-2;
   }
}

.md-table-footer {
   .md-table-row {
      border-top: 1px solid $pgdb-black-2;
      border-bottom: 2px solid $pgdb-black-2;
   }
}

.md-panel-content {
   .ReactTable .rt-thead.-header {
      box-shadow: none;
   }
   .ReactTable .-pagination {
      box-shadow: none;
   }
}
.rt-th {
   line-height: 40px;
}
.md-panel-content {
   .ReactTable {
      border: none;
   }
}
//================== Custom Table End ==================

.btn-small {
   padding: 8px 16px;
   font-size: 13px;
}

.btn-grey {
   background-color: $pgdb-darkgrey;
}

.btn-grey:hover,
.btn-grey:focus {
   background-color: $pgdb-grey;
}

.center {
   margin: auto;
   width: 50%;
   padding: 10px;
   text-align: center;
   // border: 3px solid green; //For debugging
}

.md-list--menu-restricted {
   max-height: 1000px;
}

.md-list {
   border-radius: 6px;
}

.md-list.md-list--nested-1 {
   background: $pgdb-lightblue;
}

.md-text--theme-secondary {
   color: $pgdb-green;
}

nav {
   .md-list-tile:hover {
      .md-tile-text--primary {
         display: block;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: inherit;
      }
   }
}

.md-dialog--centered {
   border-radius: 6px;
}

.md-dialog-content {
   overflow-x: hidden;
}

.md-text-field.input-error {
   border: solid 3px $pgdb-red;
}

.md-text-field.quick-search-error,
.quick-search-error {
   color: $pgdb-red;
   border: solid 3px $pgdb-red;
}

.md-text--error ~ input {
   border-color: red;
}

.md-text--error + .md-select-field {
   .md-text-field {
      border-color: red;
   }
}

// limit height of drop-down and autocomplete lists to 200px
.md-select-field__toggle + ul.md-list--menu,
.md-autocomplete + ul.md-list--menu {
   max-height: 200px;
}

.extra-small .md-autocomplete + ul.md-list--menu {
   max-height: 120px;
}

.md-dialog-container.md-overlay {
   z-index: $pgdb-z-order-3;
}

.md-tab-label {
   user-select: none;
}

.md-btn:disabled {
   background: $pgdb-lightgrey;
   color: $pgdb-verylightgrey;
}

.md-text--error ~ .md-text-field-multiline-container textarea {
   border: 3px solid $pgdb-red;
}

.md-card {
   border-radius: 6px;
}

// Change checkbox size
.material-icons.md-icon {
   font-size: 24px;
}

//================== Overrides for ReactTable ====================

.ReactTable .rt-thead.-header {
   box-shadow: none;
}

.ReactTable .rt-thead .rt-th {
   line-height: 40px;
}

.ReactTable .rt-thead .rt-tr {
   border-bottom: 1px solid $pgdb-table-border;
}

.ReactTable {
   border: none;
   margin: 15px;
}

.ReactTable.-striped .rt-tr.-odd {
   background: $pgdb-table-alternate-row;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
   background: $pgdb-table-hover-row;
}

.ReactTable .rt-tbody .rt-td {
   padding: 10px;
}

.ReactTable .-pagination .-btn {
   background: $pgdb-table-alternate-row;
   border-radius: 30px;
   padding: 15px;
   font-weight: bold;
   font-size: 15px;
}

.ReactTable .-pagination {
   border-top-color: $pgdb-table-border;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
   background: $pgdb-pagination-hover-button;
}

.ReactTable .rt-tbody .rt-td {
   border-right: 1px solid $pgdb-table-border;
}

.ReactTable .-pagination .-pageInfo {
   font-family: 'HelveticaNeueLTPro-Bd';
   color: $pgdb-darkgrey;
}

.ReactTable .-pagination .-pageJump {
   font-family: 'HelveticaNeueLTPro-Bd';
   color: $pgdb-darkgrey;
}

.ReactTable .-pagination .-totalPages {
   font-family: 'HelveticaNeueLTPro-Bd';
   color: $pgdb-darkgrey;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
   font-family: 'HelveticaNeueLTPro-Bd';
   color: $pgdb-darkgrey;
   border: 1px solid $pgdb-pagination-hover-button;
   padding: 10px;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
   border-right: 1px solid $pgdb-table-border;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
   box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
   box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-desc .rt-resizable-header-content,
.ReactTable .rt-thead .rt-td.-sort-desc .rt-resizable-header-content {
   &::after {
      content: ' ▼';
   }
}

.ReactTable .rt-thead .rt-th.-sort-asc .rt-resizable-header-content,
.ReactTable .rt-thead .rt-td.-sort-asc .rt-resizable-header-content {
   &::after {
      content: ' ▲';
   }
}

.table-row-clickable {
   cursor: pointer;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
   flex: 0.8;
}

// TOAST OVERRIDES

.Toastify__toast {
   padding: 20px;
   border-radius: 15px;
}

.Toastify__toast--success {
   background: linear-gradient(180deg, #48b461, #28a745);
}

.Toastify__toast--warning {
   background: linear-gradient(180deg, #ffca2c, #ffc107);
}

.Toastify__toast--error {
   background: linear-gradient(180deg, #e15361, #dc3545);
}

.Toastify__toast--info {
   background: linear-gradient(180deg, #3ab0c3, #17a2b8);
}
