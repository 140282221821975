@import '../../../Style/variables';

.md-tab-panel.contact {
   min-height: 300px;
}

.contact {
   .grey-background {
      background-color: $pgdb-card-grey;
      border-radius: 6px;
   }
}

.create-contact {
   margin-top: 80px;
   padding-left: 40px;
   padding-right: 40px;
   p {
      font-family: 'HelveticaNeueLTPro-Bd';
      font-size: 14px;
   }
   hr {
      height: 1px;
      border: 0;
      border-top: 2px solid $pgdb-lightgrey;
      margin: 1em 0;
      padding: 0;
   }
   h2 {
      font-family: 'HelveticaNeueLTPro-Bd';
      font-size: 24px;
   }
   h3 {
      font-family: 'HelveticaNeueLTPro-Bd';
      font-size: 18px;
   }
   .contact-status-select {
      .md-list {
         margin-top: -15px;
      }
   }
   .padding {
      padding: 16px;
   }
   .upload-box {
      cursor: pointer;
      height: 155px;
      width: 155px;
      background-color: $pgdb-lightgrey;
      border-radius: 6px;
      text-align: center;
      .md-icon {
         padding-top: 35px;
         font-size: 60px;
         cursor: pointer;
      }
      p {
         font-family: 'HelveticaNeueLTPro-Bd';
         font-size: 12px;
         padding-top: 20px;
      }
   }

   .profile-photo {
      .photo-text {
         margin-top: 85px;
      }
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: table;
      p {
         color: transparent;
      }
      &:hover {
         border: solid 1px $pgdb-activeblue;
         p {
            color: $pgdb-white;
         }
         a {
            display: table-cell !important;
            background-color: rgba(0, 0, 0, 0.3);
            font-weight: bold;
            text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px,
               $pgdb-blue 0px 0px 20px, $pgdb-blue 0px 0px 30px,
               $pgdb-blue 0px 0px 40px, $pgdb-blue 0px 0px 50px,
               $pgdb-blue 0px 0px 75px;
         }
      }
   }

   .controls {
      padding-right: 50px;
      text-align: right;
   }
}

.contact-edit-dialog {
   z-index: $pgdb-z-order-2;
}

.business-contact-group {
   padding: 0px;
   width: 100%;
   .md-cell {
      &:first-child {
         margin-left: 5px;
         padding-left: 0px;
      }
      &:last-child {
         margin-left: 4px;
         margin-right: 5px;
         padding-right: 0px;
      }
   }
}

.business-phone-number{
   label{
      overflow:hidden;
      text-overflow: "";
      width: calc(100%);
   }
   
}

.public-register-section {
   .business-address-check {
      .md-selection-control-label {
         min-height: 60px;
      }
   }
}