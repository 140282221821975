@import '../../../Style/variables';
/* ========== Card Layout ========== */

.licence-card {
   border-radius: 70px;
   border-top-right-radius: 200px;
   border-top-left-radius: 200px;
   height: 218px;
   width: 111px;
   color: white;
   margin-bottom: 20px;
   text-align: center;
   margin-right: 5px;
   margin-left: 5px;
   > {
      p {
         color: white;
      }
      .trade {
         font-size: 11px;
         margin-bottom: -25px;
         margin-top: -15px;
         padding-bottom: 20px;
         font-family: 'HelveticaNeueLTPro-Bd';
      }
      .level {
         font-size: 11px;
         font-family: 'HelveticaNeueLTPro-Bd';
         min-height: 20px;
      }
      .date {
         font-size: 8px;
      }
      .year {
         font-size: 11px;
      }
      i {
         color: white;
         text-align: center;
         font-size: 2rem;
         margin-top: -15px;
      }
   }
}

.licence-card a,
.licence-card span {
   font-size: 12px;
   font-family: 'HelveticaNeueLTPro-Bd';
   color: $pgdb-white;
   text-align: center;
}

/* ========== Purple ========== */

.purple-licence {
   background-image: $pgdb-licence-purple-grad;
}

/* ========== Orange ========== */

.orange-licence {
   background-image: $pgdb-licence-orange-grad;
}

/* ========== Green ========== */

.green-licence {
   background-image: $pgdb-licence-green-grad;
}

/* ========== Pending ========== */

.pending-licence {
   background-image: $pgdb-licence-pending-grad;
   color: black;
   .year,
   .trade,
   .date {
      color: black;
   }
   i {
      color: black;
   }
   > {
      p:nth-child(2) {
         color: black;
      }
   }
}

pending-licence a,
.pending-licence span {
   color: black;
}

/* ========== Disabled ========== */

.disabled-licence {
   background-image: $pgdb-licence-disabled-grad;
   color: black;
   .year,
   .trade,
   .date {
      color: $pgdb-darkgrey;
   }
   > {
      p:nth-child(2) {
         color: $pgdb-darkgrey;
      }
   }
}

.disabled-licence i {
   color: $pgdb-red;
}

.disabled-licence a,
.disabled-licence span {
   color: $pgdb-red;
}

/* ============================== */

.card-image {
   background-position: 20px;
   margin-top: 13px;
   width: 74px;
   height: 74px;
   border-radius: 360px;
}

.opacity-01 {
   opacity: 0.1;
}

.renew-btn {
   margin-top: 10px;
   margin-bottom: 20px;
}

div.change-hover div.renew-action {
   display: inline;
}
div.change-hover:hover div.renew-action {
   display: none;
}
div.change-hover div.change-action {
   display: none;
}
div.change-hover:hover div.change-action {
   display: inline;
   cursor: pointer;
}
