@import '../../../../../../Style/variables.scss';

.note-item {
   margin-bottom: 0.5em;

   .header {
      border-bottom: 1px solid $pgdb-white;
      margin-bottom: 1em;
      padding-bottom: 0.25em;
      font-weight: 400;
   }

   .content {
      word-wrap: break-word;
      text-align: justify;
      margin-bottom: 2em;
   }

   .details-container {
      display: inline;

      .detail {
         display: inline-block;
         width: 50%;

         .download-button {
            padding: 10px 20px;
         }
      }
   }
}
