@import '../../../../Style/variables';
.contact-supervision {
   .supervision-modal {
      .form-section {
         margin-bottom: 20px;
      }
      .cancel-button {
         margin-left: 8px;
      }
      .md-text-field-inline-indicator {
         top: 15px;
         right: 10px;
      }
   }

   .supervision-history {
      width: 100%;

      .sub-table-wrapper {
         padding: 30px 80px;
         .rt-tr {
            cursor: default;
         }
      }

      .sub-table-header {
         padding-bottom: 10px;
         font-family: 'HelveticaNeueLTPro-Bd';
         border-bottom: 1px solid $pgdb-lightgrey;
      }
   }

   .supervisee-table {
      width: 100%;

      .supervisee-action-wrapper {
         display: flex;
      }

      .supervisee-action {
         margin: 0 7px;
         cursor: pointer;
         color: $pgdb-activeblue;
      }
      .fa-times {
         color: $pgdb-red;
      }
      .fa-check {
         color: $pgdb-green;
      }
   }

   .md-tabs.md-background--primary,
   .md-tab--inactive {
      background: $pgdb-white;
   }
}
