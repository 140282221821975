@import '../../../../Style/variables';

.contact-overview-tab {
   .year-footer {
      color: black;
      font-family: 'HelveticaNeueLTPro-Bd';
      font-size: 24px;
      text-align: center;
      position: relative;
      z-index: 1;
      overflow: hidden;
   }
   .year-footer:before,
   .year-footer:after {
      position: absolute;
      top: 51%;
      overflow: hidden;
      width: 18%;
      height: 2px;
      content: '\a0';
      margin-left: 14px;
      background-color: $pgdb-lightgrey;
   }
   .year-footer:before {
      margin-left: -20%;
      text-align: right;
   }

   .center-cards {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .no-licence {
      text-align: center;
      color: $pgdb-darkgrey;
      font-style: italic;
      margin-left: 75px;
      margin-top: 30%;
   }
}

.contact-edit-dialog {
   min-height: 500px;
   .md-dialog--full-page {
      border-radius: 10px;
      padding: 10px;
      margin: 10px;
      width: 99%;
      min-width: 80%;
      .create-contact {
         display: contents;
      }
   }
   .button-close {
      margin: 10px;
      float: right;
   }
   .md-dialog-content {
      overflow: auto;
   }
}

.letter-selection-dialog {
   min-width: 500px;
   .ReactTable {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
   }
   .md-dialog {
      min-height: 400px;
   }
   .md-title {
      font-size: 24px;
      padding-bottom: 5px;
   }
}
