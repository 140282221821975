@import '../../../Style/variables';

.photo-editor-container > {
   .photo-editor {
      display: inline-flex;
      padding: 0px;
      > {
         .avatar-frame {
            max-width: 200px;
         }
         .avatar-frame > canvas {
            border: solid 4px $pgdb-darkgrey;
            border-radius: 0;
            background-image: url(/images/grid.svg);
            background-size: auto auto;
         }
         .avatar-control-frame {
            width: 200px;
            height: 330px;
            height: 332px;
            margin-left: 176px;
            background-color: $pgdb-darkgrey;
            > .control-button-list {
               display: inline-grid;
               padding-left: 15px;
               padding-right: 10px;
               padding-top: 10px;
               margin-top: 10px;
            }

            ul > li {
               color: $pgdb-verylightgrey;
               display: inline-flex;
               margin-top: 20px;
               background: transparent;
               user-select: none;
               &:hover {
                  color: $pgdb-activeblue;
               }
               > span {
                  cursor: pointer;
                  padding-left: 10px;
                  padding-left: 10px;
               }
            }
         }
      }
   }
   .photo-footer {
      button {
         margin-top: 10px;
      }
   }
   .controls button {
      margin: 20px;
   }
}
