@import '../../../../../Style/variables.scss';

.add-user-container {
   .controls {
      text-align: right;
   }

   .user-role-select {
      .md-list {
         top: 55px;
      }
   }
}
