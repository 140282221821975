@import '../../../../../../../Style/variables';

.fit-and-proper-edit {
    width: 100%;
    margin: 0;
    padding: 0;

    h2 {
        border-bottom: solid 1px $pgdb-activeblue;
    }
    
    .fp-edit-question {
        margin-top: 50px;
        font-size: large;
        color: $pgdb-blue-2;
        text-align: center;
    }

    .fp-options {
        cursor: pointer;
        font-size: medium;
        text-align: center;
        margin: 50px;

        
        label {
            cursor: pointer;
            margin: 5px;
            padding: 10px;
            font-size: large;
            border: solid 1px $pgdb-card-grey;
            background-color: $pgdb-blue-grad;
            
            input {
                padding: 5px;
            }
        }
        
        input[type="radio"] {
            cursor: pointer;
            margin: 5px;

            font: inherit;
            color: $pgdb-activeblue;
            width: 1.2em;
            height: 1.2em;
            border: 0.15em solid $pgdb-activeblue;
            border-radius: 50%;  
          }       
    }

    .controls {
        margin-top: 25px;
        text-align: right;
    }
}