@import 'react-md/src/scss/_colors.scss';

//================ Variables ================
// Base style colors
$pgdb-blue: #00539f;
$pgdb-lightblue: #a0cae4;
$pgdb-lighterblue: #ecf9ff;
$pgdb-verylightblue: #f6fbff;
$pgdb-black: #000000;
$pgdb-darkgrey: #666666;
$pgdb-grey: #c4c4c4;
$pgdb-lightgrey: #e4e4e4;
$pgdb-verylightgrey: #f5f5f5;
$pgdb-purple: #9561a8;
$pgdb-hot-pink: #ff69b4;
$pgdb-orange: #f78f1e;
$pgdb-green: #8cc63f;
$pgdb-red: #ee3224;
$pgdb-activeblue: #00afdb;
$pgdb-white: #ffffff;
$pgdb-black-2: #08080d;
$pgdb-blue-2: #0058a8;
$border-light: #d6d6d6;
$pgdb-card-grey: #f5f5f5;
$pgdb-flag-red: #d0021b;
$pgdb-table-alternate-row: #ecf4f7;
$pgdb-table-hover-row: #cae6f0;
$pgdb-table-border: rgba(0, 0, 0, 0.05);
$pgdb-pagination-hover-button: #71d5eb;
$pgdb-background: #f4f4f4;
$pgdb-background-selected-rows: #e6ffe6;

// Variable overrides
$md-primary-color: $pgdb-activeblue;
$md-secondary-color: $pgdb-orange;

// Panel gradient
$pgdb-light-blue-grad: linear-gradient(180deg, #77dbeb 0%, #42b4d3 100%);
$pgdb-blue-grad: linear-gradient(0deg, #0058a8 0%, #288ce8 100%);
$pgdb-green-grad: linear-gradient(0deg, #2c6316 0%, #429321 100%);
$pgdb-purple-grad: linear-gradient(0deg, #06010b 0%, #825396 100%);
$pgdb-orange-grad: linear-gradient(0deg, #c3511d 0%, #f78f1e 100%);
$pgdb-red-grad: linear-gradient(0deg, #b90909 0%, #f71e1e 100%);

$pgdb-licence-purple-grad: linear-gradient(#9561a8, #672e8f);
$pgdb-licence-orange-grad: linear-gradient(#f78f1e, #c3511d);
$pgdb-licence-green-grad: linear-gradient(#8cc63f, #328b39);
$pgdb-licence-pending-grad: linear-gradient(#e4e4e4, #c4c4c4);
$pgdb-licence-disabled-grad: linear-gradient(#e4e4e47a, #c4c4c47a);

$pgdb-z-order-1: 1000;
$pgdb-z-order-2: 1001;
$pgdb-z-order-3: 1002;
