@import '../../../Style/variables';

$notification-mono-fonts: Consolas, Menlo, Monaco, Lucida Console,
   Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New,
   monospace, sans-serif;

.notification-edit-area {
   .md-text-field-container {
      margin: 0;
   }

   .md-text-field:focus {
      border: 1px solid $pgdb-activeblue;
   }

   .md-text-field--margin {
      margin: 0;
      margin-top: -302px;
   }
}

.notification-maintenance {
   .ReactTable .rt-th,
   .ReactTable .rt-td {
      white-space: pre-line;
      overflow: visible;
   }
   code {
      font-family: $notification-mono-fonts;
      background-color: #eff0f1;
   }
   pre {
      margin-bottom: 1em;
      padding: 12px 8px;
      padding-bottom: 20px;
      width: auto;
      max-height: 600px;
      overflow: auto;
      font-family: $notification-mono-fonts;
      font-size: 13px;
      background-color: #eff0f1;
      border-radius: 3px;
   }

   .container,
   .backdrop,
   textarea {
      height: 302px;
      max-height: 302px;
   }

   .highlights,
   textarea {
      padding: 10px;
      font-family: $notification-mono-fonts;
   }

   .backdrop {
      margin-top: 10px;
      z-index: 1;
      border: 1px solid transparent;
      border-radius: 6px;
      background-color: #fff;
      overflow: hidden;
      pointer-events: none;
      transition: transform 1s;
      width: auto;
      display: block;
   }

   .highlights {
      white-space: pre-wrap;
      word-wrap: break-word;
      color: transparent;
   }

   textarea {
      z-index: 2;
      margin: 0;
      background-color: transparent;
      max-height: 302px;
   }

   mark {
      border-radius: 3px;
      color: transparent;
      background-color: #b1d5e5;
      font-family: $notification-mono-fonts;
   }

   .invalid-param {
      border-radius: 3px;
      color: transparent;
      background-color: #f2b1b1;
   }

   .for-loop {
      border-radius: 3px;
      color: transparent;
      background-color: #defadf;
   }

   .trigger-btn {
      margin-top: -55px;
   }
}
