@import '../../../Style/variables';

.PendingRenewalApplication {
   width: 100%;

   .fa-check {
      color: $pgdb-green;
   }
   .fa-times {
      color: $pgdb-flag-red;
   }

   .rt-tbody .rt-td {
      text-align: center;
      border-right: none;
   }
   
   .rt-tbody .rt-tr-group {
      border-bottom: none;
   }
}
