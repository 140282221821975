.fee-type-results {
   .rt-tbody {
      overflow: visible;

      .md-menu--select-field {
         margin: 0;
      }
      .md-menu-container,
      .md-text-field-container {
         margin: 0;
      }
      .md-list--menu-restricted {
         max-height: 400px;
      }
   }
}

.fee-type-modal {
   .form-section {
      margin-bottom: 20px;
   }

   .cancel-button {
      margin-left: 8px;
   }

   .form-input {
      margin-top: 0;
      margin-left: 5px;
   }

   .md-menu-container,
   .md-text-field-container {
      margin-top: 0;
      margin-left: 5px;
   }
}
