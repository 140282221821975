@import '../../../Style/variables';

.header-search {
   width: 100%;
   display: flex;
   flex-direction: row-reverse;
   margin-top: 0;
   background: $pgdb-white;

   .search-container {
      margin-top: 12px;
      width: 300px;
      color: $pgdb-darkgrey;

      input[type='text'],
      input[type='search'] {
         height: 40px;
         padding: 0 50px 0 15px;
      }
   }
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
   display: none;
   width: 0;
   height: 0;
}
input[type='search']::-ms-reveal {
   display: none;
   width: 0;
   height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
   display: none;
}

.quick-search-icon:hover {
   color: $pgdb-activeblue;
}

.quick-search-icon.quick-search-error {
   color: $pgdb-red;
   border: none;
}

.search-button {
   margin-top: 30px;
}

.search-button:disabled {
   color: $pgdb-white;
   background-color: $pgdb-grey;
}

// Mini drawer
.md-drawer--mini {
   overflow-y: auto;

   .md-tile-content--left-icon {
      display: none;
   }
   .md-list--nested-1 {
      .md-list-tile {
         padding-left: 16px;
      }
   }
   .md-tile-addon--icon {
      .md-collapser {
         margin-left: 4px;
      }
   }
   .material-icons.md-icon {
      font-size: 20px;
   }
   .md-icon {
      font-size: 20px;
      width: 20px;
   }
}

.md-toolbar {
   .md-toolbar--action-left {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
   }
   .md-btn--toolbar {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
   }
}

// Menu
#toolbar-title-menu-kebab {
   margin-top: 0;
   height: 100%;
   #toolbar-title-menu-kebab-toggle {
      height: 100%;
      padding: 10px;
      border-radius: 0;
      margin: 0;
   }
   .md-tile-content--left-icon {
      padding-left: 8px;
      padding-top: 5px;
   }
   .md-icon {
      font-size: 15px;
   }
   .md-tile-addon--icon {
      height: 8px;
   }
   .md-list-tile {
      padding-left: 8px;
      padding-right: 8px;
   }
   #toolbar-title-menu-kebab-list {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width: auto;
   }
}
