@import '../../../../../../../Style/variables';


.fit-and-proper-history {
    width: 95%;
    margin-top: 35px;
    min-height: 20%;
    max-height: 80%;
    padding-left: 25px;

    h2 {
        margin: 25px;
    }

    .question-section {
        padding: 10px;
        font-size: medium;
        border-bottom: solid 1px $pgdb-activeblue;
    }
    
    .reason-column {
        white-space: break-spaces;
        text-align: left;
      }

    .answer-column {
        text-align: center;
    }
    
    .controls {
        margin-top: 25px;
        text-align: right;
    }

    .rt-tr {
        color: $pgdb-grey;
        border-bottom: solid 1px $pgdb-lightgrey;
    }
    
    .rt-tr-group:first-child {
        background-color: rgba(152, 214, 255, 0.308);
        font-weight: bold;
        
        .rt-td {
            color: black;
        }
    }

    .loading-button {
        text-align: center;
        margin-top: 100px;
        min-height: 200px;
        .loading-text {
            margin: 25px;
            text-align: center;
        }
        button {
            background-color: transparent;
        }
    }
}